import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { useSelector } from "react-redux";
import axios from "axios";
import "./QuizResult.css";
import { message } from "antd";

const QuizResult = () => {
  const { user } = useSelector((state) => state.user);
  const [userQuiz, setUserQuiz] = useState(null);
  const [allQuiz, setAllQuiz] = useState([]);

  const getAllQuiz = async () => {
    try {
      const res = await axios.get("/api/quiz/get-quiz");
      if (res.data.success) {
        setAllQuiz(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserQuiz = async () => {
    try {
      const res = await axios.post("/api/user/get-user-quiz", {
        email: user?.email,
      });
      if (res.data.success) {
        setUserQuiz(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      getUserQuiz();
      getAllQuiz();
    }
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Result will be announced here!</b>
          </span>
        </div>
        <div className="quiz-result-container">
          <table className="table">
            <thead>
              <tr className="bg-dark">
                <th>#</th>
                <th>Quiz Name</th>
                <th>Date</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {userQuiz &&
                userQuiz.map((quiz, index) => {
                  const matchingQuiz = allQuiz.find(
                    (q) => q._id === quiz.quizId
                  );

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{quiz?.quizName}</td>
                      <td>{quiz?.quizDate}</td>
                      <td>
                        {matchingQuiz && matchingQuiz.result
                          ? quiz.score
                          : "Pending"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default QuizResult;
