import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { message } from "antd";
import "./AddQuiz.css";
import { useNavigate } from "react-router-dom";

const AddQuiz = () => {
  const navigate = useNavigate();
  const [currentQuiz, setCurrentQuiz] = useState(1);
  const [quizName, setQuizName] = useState("");
  const [quizTime, setQuizTime] = useState(null);
  const [quizPrice, setQuizPrice] = useState(null);
  const [quizDate, setQuizDate] = useState(null);
  const [formFields, setFormFields] = useState([
    { question: "", options: [], answer: null },
  ]);

  // for input
  const handleFormChange = (event, index, fieldName, optionIndex) => {
    let data = [...formFields];
    if (!data[index].options) {
      data[index].options = [];
    }

    if (fieldName === "question" || fieldName === "answer") {
      data[index][fieldName] = event.target.value;
    } else if (fieldName === "option") {
      data[index].options[optionIndex] = event.target.value;
    }
    setFormFields(data);
  };

  // form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      quizName: quizName,
      quizTime: quizTime,
      quizPrice: quizPrice,
      quizDate: quizDate,
      questions: formFields,
    };
    try {
      const res = await axios.post("/api/quiz/add-quiz", formData);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/admin-quiz");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };

  // add fields
  const addFields = () => {
    let object = {
      question: "",
      options: [],
      answer: null,
    };
    setFormFields([...formFields, object]);
  };

  // remove fields
  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  return (
    <Layout>
      <DashboardLayout>
        <div className="add-quiz-container">
          <button onClick={addFields} className="add-more-button">
            <AddIcon className="add-icon" />
          </button>
          <h5 className="p-0">Add Quiz</h5>
          <div className="row gap-3">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 p-0 form-fields">
              <label htmlFor="">Quiz Name</label>
              <input
                className="form-control"
                type="text"
                name="quizName"
                placeholder="Enter quiz name"
                onChange={(e) => setQuizName(e.target.value)}
                value={quizName}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 p-0 form-fields">
              <label htmlFor="">Quiz Price</label>
              <input
                className="form-control"
                type="text"
                name="quizPrice"
                placeholder="Enter quiz price"
                onChange={(e) => setQuizPrice(e.target.value)}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 p-0 form-fields">
              <label htmlFor="">Quiz Time</label>
              <input
                className="form-control"
                type="text"
                name="quizTime"
                placeholder="Enter time in minutes"
                onChange={(e) => setQuizTime(e.target.value)}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 p-0 form-fields">
              <label htmlFor="">Quiz Date</label>
              <input
                className="form-control"
                type="date"
                name="quizDate"
                onChange={(e) => setQuizDate(e.target.value)}
              />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row mt-4">
              {formFields?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="border bg-white p-3 col-12 col-sm-12 col-md-6 col-lg-6"
                  >
                    <div className="mb-3 form-fields">
                      <label
                        className="d-flex justify-content-between form-label"
                        htmlFor="name"
                      >
                        {index + 1}. Question
                        <span>
                          <DeleteIcon
                            onClick={() => removeFields(index)}
                            className="text-danger"
                          />
                        </span>
                      </label>
                      <input
                        placeholder="Enter your question"
                        className="form-control"
                        type="text"
                        name={`question-${index}`}
                        onChange={(e) => handleFormChange(e, index, "question")}
                        value={item.question}
                      />
                    </div>
                    <div className="row">
                      {[0, 1, 2, 3].map((optionIndex) => (
                        <div className="col-6" key={optionIndex}>
                          <div className="mb-3 form-fields">
                            <label
                              className="form-label"
                              htmlFor={`option-${optionIndex}`}
                            >
                              Option {optionIndex + 1}
                            </label>
                            <input
                              placeholder="Enter option number"
                              className="form-control"
                              type="text"
                              name={`option-${index}-${optionIndex}`}
                              onChange={(e) =>
                                handleFormChange(
                                  e,
                                  index,
                                  "option",
                                  optionIndex
                                )
                              }
                              value={item.options[optionIndex] || ""}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mb-3 form-fields">
                      <label className="form-label" htmlFor="name">
                        Answer
                      </label>
                      <input
                        placeholder="Enter option number"
                        className="form-control"
                        type="text"
                        name={`answer-${index}`}
                        onChange={(e) => handleFormChange(e, index, "answer")}
                        value={item.answer}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <button className="quiz-submit-btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default AddQuiz;
