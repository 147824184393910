import React, { useEffect } from "react";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowItWorks from "../components/Home/HowItWorks";
import CashReward from "../components/Home/CashReward";
import AboutUs from "../components/Home/AboutUs";
import Faq from "../components/Home/Faq";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setUser } from "../redux/features/userSlice";

const Home = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Layout>
      <HeroSection />
      <CashReward />
      <AboutUs />
      <HowItWorks />
      <Faq />
    </Layout>
  );
};

export default Home;
