import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import "./Register.css";

const Register = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [form, setForm] = useState({});
  const [msg, setMsg] = useState("");

  // handle change
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/register", form);
      if (res.data.success) {
        message.success(res.data.message);
        navigate("/login");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };

  // generate referral code
  const generateRandomCharacter = (characters) => {
    const randomIndex = Math.floor(Math.random() * characters.length);
    return characters[randomIndex];
  };

  const generateInviteCode = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    let referralCode = "";
    for (let i = 0; i < 3; i++) {
      referralCode += generateRandomCharacter(letters);
    }
    for (let i = 0; i < 4; i++) {
      referralCode += generateRandomCharacter(numbers);
    }
    return referralCode;
  };

  const handleApplyCode = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/check-code", {
        referCode: form.referCode,
      });
      if (res.data.success) {
        setMsg(res.data.message);
      } else {
        setMsg(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setForm({
      ...form,
      inviteCode: generateInviteCode(),
      referCode: params && params.referCode,
    });
  }, []);

  return (
    <Layout>
      <div className="container register-container">
        <div className="row">
          <form>
            <div className="form col-12 col-sm-12 col-md-6 col-lg-6 d-block m-auto">
              <h6>Not a user? Register Now..</h6>
              <hr />
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Full Name
                </label>
                <input
                  name="name"
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Email
                </label>
                <input
                  name="email"
                  onChange={handleChange}
                  className="form-control"
                  type="email"
                />
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Mobile Number
                </label>
                <input
                  name="phone"
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Password
                </label>
                <input
                  name="password"
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Referral Code (optional)
                </label>
                <div className="d-flex">
                  <input
                    name="referCode"
                    onChange={handleChange}
                    className="form-control"
                    type="text"
                    value={params ? params.referCode : form.referCode}
                  />
                  <button onClick={handleApplyCode} className="apply-code-btn">
                    <small>Apply Code</small>
                  </button>
                </div>
                <p
                  className={`${
                    msg === "Invalid Code" ? "text-danger" : "text-success"
                  }`}
                >
                  <small>{msg && msg}</small>
                </p>
              </div>
              <div className="mb-3 register-btn">
                <button onClick={handleSubmit}>Register</button>
              </div>
              <hr />
              <p>
                Already a User? <Link to="/login">click here</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
