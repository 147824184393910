import React from "react";
import "./HeroSection.css";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../img/image";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    <div className="hero-section">
      <div className="content">
        <span style={{ color: "#1dd1a1" }}>
          <small>Genius Gate</small>
        </span>
        <h1>
          Learn from nothing <br /> to be something
        </h1>
        <p>
          Genius Gate is an online learning and teaching marketplace with over
          100+ courses that can improve your skill better
        </p>
        <div className="d-flex action-btn">
          <button
            onClick={() => navigate("/contact")}
            className="hero-btn me-2"
          >
            Contact Us
          </button>
          <button onClick={() => navigate("/register")} className="enroll-btn">
            Enroll Now
          </button>
        </div>
      </div>
      <div className="hero-bg">
        <img src={IMAGES.herobg2} alt="" />
      </div>
    </div>
  );
};

export default HeroSection;
