import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import axios from "axios";
import "./Profile.css";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";

const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const [form, setForm] = useState({});
  console.log(form);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/user", form);
      if (res.data.success) {
        message.success(res.data.message);
        dispatch(setUser(res.data.data));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (user !== null) {
      setForm(user);
    }
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>My Profile</b>
          </span>
        </div>
        <div className="profile-container">
          <form className="form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Name
                  </label>
                  <input
                    name="name"
                    onChange={handleChange}
                    value={form.name}
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Email
                  </label>
                  <input
                    name="email"
                    onChange={handleChange}
                    value={form.email}
                    className="form-control"
                    type="email"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Phone
                  </label>
                  <input
                    name="phone"
                    onChange={handleChange}
                    value={form.phone}
                    className="form-control"
                    type="phone"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Gender
                  </label>
                  <select
                    name="gender"
                    onChange={handleChange}
                    value={form.gender}
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>{" "}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Date of Birth
                  </label>
                  <input
                    onChange={handleChange}
                    value={form.dob}
                    name="dob"
                    className="form-control"
                    type="date"
                  />
                </div>
              </div>{" "}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    State
                  </label>
                  <input
                    onChange={handleChange}
                    value={form.state}
                    name="state"
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    City
                  </label>
                  <input
                    onChange={handleChange}
                    value={form.city}
                    name="city"
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>{" "}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Class
                  </label>
                  <select
                    name="class"
                    onChange={handleChange}
                    value={form.class}
                    className="form-select"
                  >
                    <option value="Select">Select</option>
                    <option value="I">I</option>
                    <option value="II">II</option>
                    <option value="III">III</option>
                    <option value="IV">IV</option>
                    <option value="V">V</option>
                    <option value="VI">VI</option>
                    <option value="VII">VII</option>
                    <option value="VIII">VIII</option>
                    <option value="IX">IX</option>
                    <option value="X">X</option>
                  </select>
                </div>
              </div>{" "}
              <div className="text-center col-12 col-sm-12 col-md-12 col-lg-12">
                <button type="submit" className="hero-btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Profile;
