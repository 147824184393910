import React from "react";
import Layout from "../Layout/Layout";
import { useParams } from "react-router-dom";
import "../../pages/Course.css";

const CourseFaq = () => {
  const params = useParams();
  return (
    <Layout>
      <div className="course-faq-container">
        {params.id === "1" && (
          <div className="container shadow p-4">
            <h2>NMMS [National Means Cum Merit Scholarship]</h2>
            <p>NMMS Syllabus 2023-24 - Highlights</p>
            <ul>
              <li>- Exam Name: National Means Cum-Merit Scholarship</li>
              <li>- Short Exam Name: NMMS</li>
              <li>- Mode of Examination: Offline</li>
              <li>- Level of the exam: State-level</li>
              <li>
                - Qualifying Cutoff: 40% in each paper (32% for reserved
                categories)
              </li>
              <li>- Questions: 180 (divided equally amongst two papers)</li>
              <li>- Total Marks: 180 (divided equally amongst two papers)</li>
              <li>- Number of papers: 2</li>
              <li>- Exam Duration: 3 hours</li>
              <li>- Type of Questions: Multiple Choice Questions (MCQ)</li>
            </ul>

            <h3>NMMS Syllabus 2023-24 for MAT</h3>
            <p>
              The MAT exam, which stands for Mental Ability Test, is designed to
              assess candidates' both verbal and nonverbal abilities.
            </p>
            <ul>
              <li>- Analogy</li>
              <li>- Classification</li>
              <li>- Numerical series</li>
              <li>- Pattern</li>
              <li>- Perception</li>
              <li>- Hidden figures</li>
            </ul>

            <h3>NMMS Syllabus 2023-24 for SAT</h3>
            <p>
              The question set for the NMMS SAT exam 2023-24 will include topics
              from both NMMS syllabus for class 8 and 7. Science, social
              science, and mathematics are the three sections of the paper.
            </p>

            <h3>NMMS Exam pattern- MAT 2023</h3>
            <table>
              <tr>
                <th>Sections</th>
                <th>Number of Questions</th>
              </tr>
              <tr>
                <td>Mental Ability</td>
                <td>45</td>
              </tr>
              <tr>
                <td>English Proficiency</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Hindi Proficiency</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>90</td>
              </tr>
            </table>

            <h3>NMMS Exam Pattern - SAT 2023</h3>
            <table>
              <tr>
                <th>Sections</th>
                <th>Number of Questions</th>
              </tr>
              <tr>
                <td>Science</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Social Studies</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Mathematics</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Total</td>
                <td>90</td>
              </tr>
            </table>
          </div>
        )}
        {params.id === "2" && (
          <div className="container shadow p-4">
            <h2>NTSE [National Talent Search Examination]</h2>
            <p>
              The National Talent Search Examination is one of the most
              prestigious scholarship examinations in India. The students are
              identified through a two-tier examination process i.e. Stage I and
              Stage II. Only students clearing the stage 1 exam are allowed to
              sit for the stage 2 examination.
            </p>

            <h3>Stage I:</h3>
            <p>
              It is conducted by different states and union territories in
              India. It is a state level examination.
            </p>

            <h3>Stage II:</h3>
            <p>
              It is conducted by the National Council of Educational Research
              and Training (NCERT). It is a national level examination.
            </p>

            <h3>NTSE Exam Pattern:</h3>
            <table>
              <tr>
                <th>Test</th>
                <th>Time Duration</th>
                <th>Total no. of Questions</th>
                <th>Marks Allotted</th>
              </tr>
              <tr>
                <td>Mental Ability Test (MAT)</td>
                <td>120</td>
                <td>100</td>
                <td>100</td>
              </tr>
              <tr>
                <td>Scholastic Aptitude Test (SAT)</td>
                <td>120</td>
                <td>100</td>
                <td>100</td>
              </tr>
            </table>

            <p>
              1 mark is awarded for every correct answer for both stage 1 and 2.
              There will be no negative marking.
            </p>

            <h3>Mental Ability Syllabus:</h3>
            <ul>
              <li>- Alphabet and Number Test</li>
              <li>- Analogy (Verbal)</li>
              <li>- Calendar, Time and Clock</li>
              <li>- Analogy (Non-Verbal)</li>
              <li>- Embedded Figure</li>
              <li>- Mathematical Operations</li>
              <li>- Ranking and Arrangements</li>
              <li>- Series (Verbal)</li>
              <li>- Water and Mirror Images</li>
              <li>- Series (Non – Verbal)</li>
              <li>- Coding-Decoding</li>
              <li>- Blood Relations</li>
              <li>- Classification</li>
              <li>- Direction Sense</li>
              <li>- Figure Partition and Dot Situation</li>
              <li>- Missing Character</li>
              <li>- Venn Diagrams</li>
              <li>- Word Problems</li>
            </ul>

            <h3>Mathematics Syllabus:</h3>
            <ul>
              <li>- Expressions in Algebra</li>
              <li>- Basic Geometry</li>
              <li>- Progressions in Arithmetic</li>
              <li>- Geometry of Coordinates</li>
              <li>- Arithmetic</li>
              <li>- Circles</li>
              <li>- Variation, both direct and inverse</li>
              <li>- Linear Equation</li>
              <li>- Mensuration</li>
              <li>- Exponent</li>
              <li>- Percentage & its Application</li>
              <li>- Number System</li>
              <li>- Quadratic Equations</li>
              <li>- Probability</li>
              <li>- Simple interest & Compound interest</li>
              <li>- Playing with Numbers</li>
              <li>- Statistics</li>
              <li>- Rational Numbers</li>
              <li>- Square Root & Cube Root</li>
              <li>- Triangles</li>
              <li>- Surface Areas and Volumes</li>
              <li>- Trigonometry</li>
            </ul>

            <h3>Science Syllabus:</h3>
            <ul>
              <li>- Air</li>
              <li>- Acid Bases and Salt</li>
              <li>- Cellular Level of Organisation</li>
              <li>- Carbon and its Compounds</li>
              <li>- Fibres and Plastics</li>
              <li>- Diversity in Living Organisms</li>
              <li>- Measurement</li>
              <li>- Life Processes</li>
              <li>- Metals & Non Metals</li>
              <li>- Human Body</li>
              <li>- Heredity and Evolution</li>
              <li>- Food Production & Management</li>
              <li>- Light and Sound</li>
              <li>- Magnetism & Electricity at Work</li>
              <li>- Micro-organisms</li>
              <li>- Intermittent Classification of Elements</li>
              <li>- Our Environment</li>
              <li>- Motion and Force</li>
              <li>- Periodic Classification of Elements</li>
              <li>- Plant and Animal Nutrition</li>
              <li>- Physical and Chemical Changes</li>
              <li>- Work and energy</li>
              <li>- The Universe</li>
              <li>- Water</li>
              <li>- Structure of Atom</li>
              <li>- Soil</li>
              <li>- Source of Energy</li>
              <li>- Reproduction</li>
              <li>- Some Common Diseases</li>
            </ul>

            <h3>Social Science Syllabus:</h3>
            <ul>
              <li>- Agriculture</li>
              <li>- Biosphere</li>
              <li>- Culture, Science and Literature</li>
              <li>- Diversity and Livelihood</li>
              <li>- Early States</li>
              <li>- French Revolution</li>
              <li>- Indian Constitution</li>
              <li>- Indian Freedom Struggle</li>
              <li>- Industrial Revolution</li>
              <li>- Inner Structure of the Earth and Rocks</li>
              <li>- Jainism, Buddhism and Conquerors from Distant Lands</li>
              <li>- Major Domains and Landforms</li>
              <li>- Archaic Architecture and Culture</li>
              <li>- Patriotism in Various Countries</li>
              <li>- New Empires and Kingdoms</li>
              <li>- Popular Movements and Social Reforms</li>
              <li>- Resources and Development</li>
              <li>- The Delhi Sultanate</li>
              <li>- The Mauryas</li>
              <li>- UN and International Agencies</li>
              <li>- Vedic Period</li>
              <li>- World History</li>
              <li>- Atmosphere</li>
              <li>- British Raj</li>
              <li>- Democracy and Elections</li>
              <li>- Early Medieval Period</li>
              <li>- Eighteenth-Century Political Formation</li>
              <li>- India and its Neighbors</li>
              <li>- Indian Economics</li>
              <li>- Indus Valley Civilization</li>
              <li>- Introduction and Sources of Ancient Indian History</li>
              <li>- Local and State Government</li>
              <li>- Maps and Globe</li>
              <li>- The motion of the Earth</li>
              <li>- Natural Vegetation</li>
              <li>- Our Country – India</li>
              <li>- Population</li>
              <li>- Solar System</li>
              <li>- The Judiciary</li>
              <li>- The Mughal Empire</li>
              <li>- Union Government</li>
              <li>- Water Resources</li>
            </ul>

            <h3>General Knowledge Syllabus:</h3>
            <ul>
              <li>- Abbreviations</li>
              <li>- Books and Authors</li>
              <li>- Sports</li>
              <li>- Awards and Recognitions</li>
              <li>- India</li>
              <li>- World</li>
            </ul>

            <h3>English Syllabus:</h3>
            <ul>
              <li>- Antonyms</li>
              <li>- One-word substitution</li>
              <li>- Reading Comprehension</li>
              <li>- Sentence Completion</li>
              <li>- English Grammar</li>
              <li>- Paragraph Completion</li>
              <li>- Rearrangement of Jumbled Words</li>
              <li>- Synonyms</li>
            </ul>
          </div>
        )}
        {params.id === "3" && (
          <div className="container shadow p-4">
            <h2>Typing Curriculum</h2>

            <h3>English Typing Basics</h3>
            <p>Learn the fundamentals of typing in English.</p>

            <h3>Improving Typing Test Speed by Type Pool Game</h3>
            <p>
              Practice and enhance your typing speed with the Type Pool Game.
            </p>

            <h3>Computer Keys Operation</h3>
            <p>
              Understand the operation of various keys on the computer keyboard.
            </p>

            <h3>Shortcut Operations in Computer</h3>
            <p>
              Learn useful keyboard shortcuts for efficient computer operation.
            </p>
          </div>
        )}
        {params.id === "4" && (
          <div className="container shadow p-4">
            <h3>Module 1: Introduction to Communication</h3>
            <ul>
              <li>What is communication?</li>
              <li>The different types of communication</li>
              <li>The importance of effective communication</li>
              <li>Communication skills for different contexts</li>
            </ul>

            <h3>Module 2: Listening Skills</h3>
            <ul>
              <li>What is active listening?</li>
              <li>The different types of listening</li>
              <li>How to improve your listening skills</li>
              <li>Listening in different contexts</li>
            </ul>

            <h3>Module 3: Speaking Skills</h3>
            <ul>
              <li>The different types of speaking</li>
              <li>How to improve your speaking skills</li>
              <li>Speaking in different contexts</li>
            </ul>

            <h3>Module 4: Reading Skills</h3>
            <ul>
              <li>The different types of reading</li>
              <li>How to improve your reading skills</li>
              <li>Reading in different contexts</li>
            </ul>

            <h3>Module 5: Writing Skills</h3>
            <ul>
              <li>The different types of writing</li>
              <li>How to improve your writing skills</li>
              <li>Writing in different contexts</li>
            </ul>

            <h3>Module 6: Non-Verbal Communication</h3>
            <ul>
              <li>What is non-verbal communication?</li>
              <li>The different types of non-verbal communication</li>
              <li>How to improve your non-verbal communication skills</li>
              <li>Non-verbal communication in different contexts</li>
            </ul>

            <h3>Module 7: Communication in the Workplace</h3>
            <ul>
              <li>Communication in meetings</li>
              <li>Communication with colleagues</li>
              <li>Communication with clients</li>
              <li>Communication in job interviews</li>
            </ul>

            <h3>Module 8: Communication in Social Settings</h3>
            <ul>
              <li>Small talk</li>
              <li>Networking</li>
              <li>Public speaking</li>
            </ul>

            <h3>Module 9: Communication in Academic Settings</h3>
            <ul>
              <li>Writing essays and reports</li>
              <li>Giving presentations</li>
              <li>Participating in class discussions</li>
            </ul>

            <h3>Module 10: Communication in a Globalized World</h3>
            <ul>
              <li>Communicating with people from different cultures</li>
              <li>Communicating online</li>
            </ul>

            <p>
              Each module would include a variety of learning activities, such
              as lectures, discussions, role-playing exercises, and group
              projects. The course would be designed to help students develop
              the communication skills they need to succeed in all aspects of
              their lives.
            </p>

            <p>
              In addition to the above modules, the course could also include
              topics such as:
            </p>

            <ul>
              <li>Communication for conflict resolution</li>
              <li>Communication for leadership</li>
              <li>Communication for persuasion</li>
              <li>Communication for networking</li>
              <li>Communication for customer service</li>
              <li>Communication for diversity and inclusion</li>
            </ul>
          </div>
        )}
        {params.id === "5" && (
          <div className="container shadow p-4">
            <h3>Intro to Web Development</h3>
            <p>Introduction to the Course</p>
            <p>How Internet Works</p>
            <p>What is Web Development</p>
            <br />
            <h3>HTML</h3>
            <p>Your first webpage</p>
            <p>The structure of a Webpage</p>
            <p>Creating a full webpage</p>
            <p>Header tags</p>
            <p>Paragraph tags</p>
            <p>Formatting tags</p>
            <p>Unordered list</p>
            <p>Ordered list</p>
            <p>Image tags</p>
            <p>Forms in HTML</p>
            <p>Tables</p>
            <p>Links</p>
            <p>HTML entities</p>
            <p>iFrame</p>
            <p>PROJECT- Putting it all together</p>
            <p>Installing VS Code</p>
            <br />
            <h3>CSS 3</h3>
            <p>What is CSS</p>
            <p>Inline CSS</p>
            <p>Internal CSS</p>
            <p>External CSS</p>
            <p>Class and id</p>
            <p>Div</p>
            <p>Color</p>
            <p>Floating</p>
            <p>Positions</p>
            <p>Margins</p>
            <p>Padding</p>
            <p>Borders</p>
            <p>Font style</p>
            <p>Styling text</p>
            <p>Aligning text</p>
            <p>Styling links</p>
            <p>FlexBox in CSS</p>
            <br />
            <h3>JavaScript-I</h3>
            <p>Inline javascript</p>
            <p>Internal javascript</p>
            <p>Accessing elements</p>
            <p>Onclick event</p>
            <p>Changing Wwbsite content</p>
            <p>Manupulating styles with javascript</p>
            <p>Disappearing circles</p>
            <p>Variables</p>
            <p>Array</p>
            <p>If statement</p>
            <p>Random number guessing game</p>
            <p>For loop</p>
            <p>While loop</p>
            <p>PROJECT-Reaction tester project</p>
            <p>External javascript</p>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CourseFaq;
