const IMAGES = {
  // LOGO
  logo: require("../img/logo.png"),

  // HOME
  herobg2: require("../img/home/hero2.png"),
  aboutImg: require("../img/home/students.webp"),

  // course
  js: require("../img/course/js.jpg"),
  course: require("../img/course/course.jpg"),
  courseBg: require("../img/course/pngegg.png"),
  web: require("../img/course/web.jpeg"),
  com: require("../img/course/com.jpeg"),
  typing: require("../img/course/typing.jpeg"),
  ntse: require("../img/course/ntse.jpeg"),
  nmms: require("../img/course/nmms.jpeg"),

  // contact
  contact: require("../img/conntact/1.jpg"),

  // barcode
  barcode: require("../img/barcode/barcode.jpeg"),
};

export default IMAGES;
