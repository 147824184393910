import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import "./Quiz.css";
import IMAGES from "../img/image";
import course from "../img/course/course.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Quiz = () => {
  const navigate = useNavigate();
  const [allQuiz, setAllQuiz] = useState([]);

  const getAllQuiz = async () => {
    try {
      const res = await axios.get("/api/quiz/get-quiz");
      if (res.data.success) {
        setAllQuiz(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllQuiz();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Welcome to Quiz Section!</b>
          </span>
        </div>
        <div className="quiz-container">
          {allQuiz.map((quiz, index) => {
            return (
              <div className="quiz-card">
                <div className="quiz-img"></div>
                <div className="quiz-content">
                  <h6>Quiz: {quiz.quizName}</h6>
                  <h6 className="text-end">Duration: {quiz.quizTime} Mins</h6>
                </div>
                <button
                  onClick={() => navigate(`/quiz-details/${quiz.quizName}`)}
                  className="view-quiz-button"
                >
                  View Details
                </button>
              </div>
            );
          })}

          {/* {quizes.map((quiz, index) => {
            return (
              <div
                className="quiz-card"
                style={{ background: `url(${quiz.img})` }}
              >
                <div className="quiz-content">
                  <span>{quiz.name}</span>
                  <button
                    onClick={() => navigate(`/quiz-details/${quiz.name}`)}
                  >
                    View Details
                  </button>
                </div>
              </div>
            );
          })} */}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Quiz;
