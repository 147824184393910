import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import InfoIcon from "@mui/icons-material/Info";
import "./Refer.css";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const Refer = () => {
  const { user } = useSelector((state) => state.user);
  const [fees, setFees] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // copy to clipboard
  const copyToClipboard = (text) => {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    alert("Link Copied");
  };

  const handleCopyClick = (link) => {
    copyToClipboard(link);
  };

  // handle refer amount
  const addReferAmount = async () => {
    try {
      const res = await axios.post("/api/user/add-refer-amount", {
        referCode: user?.referCode,
        amount: fees?.referAmount,
      });
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // handle activate
  const handleActivate = async () => {
    try {
      const res = await axios.post("/api/user/activate-account", {
        email: user?.email,
        activationFees: fees?.activationFees,
      });
      if (res.data.success) {
        dispatch(setUser(res.data.data));
        // addReferAmount();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get fees
  const getFees = async () => {
    try {
      const res = await axios.get("/api/fees/get-fees");
      if (res.data.success) {
        setFees(res.data.data[0]);
      } else {
        console.log("failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    try {
      const res = await axios.post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFees();
    getUserData();
  }, []); // You only need to fetch the fees once, so no need to include getUserData here.

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Referral Link</b>
          </span>
        </div>
        <div className="refer-container">
          {user && !user?.active && (
            <div className="not-activated">
              <h5>
                <InfoIcon className="text-danger me-1" />
                Your account is not active!
              </h5>
              <p>
                When you activate your account you will get an invite link.
                Using your invitation link you can invite unlimited users and
                can earn unlimited. So what you are waiting for? Activate your
                account now.
              </p>
              <span></span>
              {parseInt(user?.amount) >= parseInt(fees?.activationFees) ? (
                <button onClick={handleActivate} className="activate-btn">
                  Pay {fees?.activationFees} & Activate
                </button>
              ) : (
                <button
                  onClick={() => navigate("/add-money")}
                  className="activate-btn"
                >
                  Add Money & Activate
                </button>
              )}
              <p className="mt-3 text-danger">
                Note: While clicking on activate your money will get deducted
                from your wallet
              </p>
            </div>
          )}

          {/* refer link */}
          {user && user?.active && (
            <div className="not-activated">
              <h6>
                Referral Link -{" "}
                {`https://geniusgate.co.in/register/${user?.inviteCode}`}
              </h6>
              <button
                className="activate-btn"
                onClick={() =>
                  handleCopyClick(
                    `https://geniusgate.co.in/register/${user?.inviteCode}`
                  )
                }
              >
                Copy Link
              </button>
            </div>
          )}
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Refer;
