import React from "react";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import Layout from "../components/Layout/Layout";

const MyQuiz = () => {
  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Your Quiz History</b>
          </span>
        </div>
        <div className="myquiz-container"></div>
      </DashboardLayout>
    </Layout>
  );
};

export default MyQuiz;
