import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import IMAGES from "../../img/image";
import { setUser } from "../../redux/features/userSlice";
import axios from "axios";
// import Inquiry from "../home/Inquiry";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [sideMenu, setSideMenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  return (
    <header className="header">
      <div className="header-main">
        <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
        <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
        <div className="logo" onClick={() => navigate("/")}>
          <img src={IMAGES.logo} alt="" />
        </div>
        <div className="menus d-none d-md-none d-lg-block">
          <ul className="p-0">
            {!user && (
              <li>
                <Link to="/">Home</Link>
              </li>
            )}
            {user && (
              <li>
                <Link to="/user-dashboard">Dashboard</Link>
              </li>
            )}
            <li>
              <Link to="/course">Courses</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            {/* <li>
              <Link to="/service">Services</Link>
            </li> */}
            <div className="action-btn">
              {!user && (
                <button
                  onClick={() => navigate("/login")}
                  className="request-btn me-2"
                >
                  Login
                </button>
              )}
              {!user && (
                <button
                  onClick={() => navigate("/register")}
                  className="request-btn"
                >
                  Register
                </button>
              )}
              {user && (
                <button onClick={handleLogout} className="request-btn">
                  Logout
                </button>
              )}
            </div>
          </ul>
        </div>
        <div
          className="burger-icon d-block d-lg-none"
          onClick={() => setSideMenu(!sideMenu)}
        >
          <MenuOpenIcon className="icon" />
        </div>
      </div>
    </header>
  );
};

export default Header;
