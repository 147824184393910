import React from "react";
import "./Text.css";

const Text = () => {
  return (
    <>
      <div className="container text-container border shadow p-3 pt-4 bg-white">
        <h3 className="mb-4" style={{ padding: "0 12px" }}>
          About Us
        </h3>
        <p className="text-muted container">
          Welcome to Genius Gate, your trusted source for high-quality online
          quizzes and courses that empower individuals to learn and grow. We are
          passionate about education and dedicated to helping you acquire
          valuable skills and knowledge.
          <hr className="m-0 my-2" />
        </p>
        <p className="text-muted container">
          Genius Gate is an ed-tech platform.
          <hr className="m-0 my-2" />
          we've been assisting learners of all ages in learning the trendiest
          courses in any field with diversified bundles.
          <hr className="m-0 my-2" />
          Genius Gate keeps culture and learning at the root of everything it
          does.
          <hr className="m-0 my-2" />
          We deliver courses and bundles designed, created, and produced with
          real-world statistical data input for the folks we highly believe in.
        </p>
      </div>
    </>
  );
};

export default Text;
