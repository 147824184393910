import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout/Layout";

const TestSubmit = ({ score }) => {
  const navigate = useNavigate();
  return (
    <div className="test-submit-container">
      <h5>Your Test has submitted successfully</h5>
      <h5>Result will be announced soon.</h5>
      {/* <h5>Score: {score}</h5> */}
      <button onClick={() => navigate("/user-dashboard")}>
        Go back to Dashboard
      </button>
    </div>
  );
};

export default TestSubmit;
