import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./AdminQuiz.css";
import { message } from "antd";

const AdminQuiz = () => {
  const navigate = useNavigate();
  const [allQuiz, setAllQuiz] = useState([]);
  console.log(allQuiz);

  const deleteQuiz = async (id) => {
    try {
      const res = await axios.post("/api/quiz/delete-quiz", { quizId: id });
      if (res.data.success) {
        message.success(res.data.message);
        getAllQuiz();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateQuiz = async (id) => {
    try {
      const res = await axios.post("/api/quiz/update-quiz", { quizId: id });
      if (res.data.success) {
        message.success(res.data.message);
        getAllQuiz();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllQuiz = async () => {
    try {
      const res = await axios.get("/api/quiz/get-quiz");
      if (res.data.success) {
        setAllQuiz(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllQuiz();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="admin-quiz-container">
          <div className="header">
            <h5 className="p-0">All Quiz</h5>
            <button
              className="add-quiz-btn"
              onClick={() => navigate("/add-quiz")}
            >
              Add Quiz
            </button>
          </div>
          <div className="all-quiz row gap-2">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Quiz Name</th>
                  <th scope="col">Quiz Time</th>
                  <th scope="col">Quiz Date</th>
                  <th scope="col">Result Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {allQuiz &&
                  allQuiz.map((quiz, index) => {
                    return (
                      <tr key={index}>
                        <td data-label="#">{index + 1}</td>
                        <td data-label="Quiz Name">{quiz.quizName}</td>
                        <td data-label="Quiz Time">{quiz.quizTime}</td>
                        <td data-label="Quiz Date">{quiz.quizDate}</td>
                        <td data-label="Result Status">
                          {quiz.result ? "Success" : "Pending"}
                        </td>
                        <td data-label="Action">
                          {!quiz.result && (
                            <button
                              onClick={() => handleUpdateQuiz(quiz._id)}
                              className="delete-quiz-btn bg-warning me-2"
                            >
                              Declare
                            </button>
                          )}
                          <button
                            onClick={() => deleteQuiz(quiz._id)}
                            className="delete-quiz-btn"
                          >
                            Delete
                          </button>
                          <button
                            onClick={() =>
                              navigate(
                                `/quiz-leaderboard/${quiz.quizName}/${quiz._id}`
                              )
                            }
                            className="delete-quiz-btn bg-warning ms-2"
                          >
                            Ranking
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default AdminQuiz;
