import React, { useEffect, useState } from "react";
import "./WithdrawalRequest.css";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { message } from "antd";
import axios from "axios";

const WithdrawalRequest = () => {
  const [withdrawals, setWithdrawals] = useState(null);
  const [select, setSelect] = useState("All");

  const handleApprove = async (id) => {
    try {
      const res = await axios.post("/api/withdraw/approve-withdrawal", { id });
      if (res.data.success) {
        message.success(res.data.message);
        getAllWithdrawals();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const handleReject = () => {};

  const getAllWithdrawals = async () => {
    try {
      const res = await axios.get("/api/withdraw/get-withdrawals");
      if (res.data.success) {
        setWithdrawals(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWithdrawals();
  }, []);
  const data = withdrawals?.filter((item) => item.status === select);
  const withdrawalData = select === "All" ? withdrawals : data;
  return (
    <Layout>
      <DashboardLayout>
        <div className="header">
          <div className="d-flex justify-content-between ">
            <h5 className="p-0">Withdrawal List</h5>
            <select
              name="select"
              id=""
              onChange={(e) => setSelect(e.target.value)}
            >
              <option value="All">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              {/* <option value="reject">Reject</option> */}
            </select>
          </div>
        </div>
        <div className="all-quiz row gap-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">Amount</th>
                <th scope="col">Method</th>
                <th scope="col">Details</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {withdrawals &&
              withdrawalData?.map((item, index) => {
                return (
                  <tbody>
                    <tr>
                      <td data-label="#">{index + 1}</td>
                      <td data-label="Email">{item?.email}</td>
                      <td data-label="Amount">{item?.amount}</td>
                      <td data-label="Method">{item?.method}</td>
                      <td data-label="Details">
                        <button
                          type="button"
                          className="delete-quiz-btn my-2 bg-warning"
                          data-bs-toggle="modal"
                          data-bs-target={`#exampleModal-${item?._id}`}
                        >
                          View
                        </button>
                      </td>
                      <td data-label="Status">{item?.status}</td>
                      <td data-label="Date">
                        {new Date(item?.date).toLocaleString("en", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </td>
                      <td data-label="Action">
                        {item?.status === "pending" ? (
                          <>
                            <button
                              onClick={() => handleApprove(item?._id)}
                              className="delete-quiz-btn me-2 my-2"
                            >
                              Approve
                            </button>
                            {/* <button
                              onClick={handleReject}
                              className="delete-quiz-btn bg-danger text-white"
                            >
                              Reject
                            </button> */}
                          </>
                        ) : (
                          "--"
                        )}
                      </td>
                    </tr>
                    <div
                      className="modal fade"
                      id={`exampleModal-${item._id}`}
                      tabIndex="-1"
                      aria-labelledby={`exampleModalLabel-${item._id}`}
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5
                              className="modal-title"
                              id={`exampleModalLabel-${item._id}`}
                            >
                              Payment Details
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <p>
                              Holder Name - {item?.paymentDetails?.holderName}
                            </p>
                            <p>
                              Bank Account Number -{" "}
                              {item?.paymentDetails?.accountNumber}
                            </p>
                            <p>IFSC - {item?.paymentDetails?.ifsc}</p>
                            <p>
                              Paytm/Gpay/Phonepe - {item?.paymentDetails?.paytm}
                            </p>
                            <p>UPI - {item?.paymentDetails?.upi}</p>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </tbody>
                );
              })}
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default WithdrawalRequest;
