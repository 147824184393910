import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import QuizIcon from "@mui/icons-material/Quiz";
import { useSelector } from "react-redux";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [inviteCode, setInviteCode] = useState(null);
  const [referCount, setReferCount] = useState(null);

  // COUNT
  const [users, setUsers] = useState(null);
  const [payment, setPayment] = useState(null);
  const [withdrawals, setWithdrawals] = useState(null);
  const [allQuiz, setAllQuiz] = useState([]);

  // get all payment
  const getAllPayment = async () => {
    axios
      .get("/api/payment/get-all-payment")
      .then((res) => {
        setPayment(res.data.data.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllUsers = async () => {
    try {
      const res = await axios.get("/api/user/get-all-users");
      if (res.data.success) {
        setUsers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        axios
          .post("/api/user/refer-count", {
            inviteCode: res.data.data.inviteCode,
          })
          .then((res) => {
            setReferCount(res.data.data);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllWithdrawals = async () => {
    try {
      const res = await axios.get("/api/withdraw/get-withdrawals");
      if (res.data.success) {
        setWithdrawals(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllQuiz = async () => {
    try {
      const res = await axios.get("/api/quiz/get-quiz");
      if (res.data.success) {
        setAllQuiz(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllUsers();
    getUserData();
    getAllPayment();
    getAllWithdrawals();
    getAllQuiz();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Welcome! {user?.name}</b>
          </span>
        </div>

        {/* ====================== USER USER USER USER =========================== */}
        {!user?.isAdmin && (
          <div className="dashboard-container">
            <div className="dashboard-card">
              <div className="card-icon">
                <PersonAddAltIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{referCount?.length || 0}</div>
                <div className="name">
                  <span>
                    <small>Referred Users</small>
                  </span>
                </div>
              </div>
            </div>
            <div className="dashboard-card">
              <div className="card-icon">
                <AccountBalanceWalletIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{user?.amount}</div>
                <div className="name">
                  <span>
                    <small>My Balance</small>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="dashboard-card"
              // onClick={() => navigate("/myquiz")}
            >
              <div className="card-icon">
                <QuizIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{user?.quiz?.length}</div>
                <div className="name">
                  <span>
                    <small>My Quiz</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ====================== ADMIN ADMIN ADMIN ADMIN =========================== */}

        {user?.isAdmin && (
          <div className="dashboard-container">
            <div
              className="dashboard-card"
              onClick={() => navigate("/all-users")}
            >
              <div className="card-icon">
                <PersonAddAltIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{users?.length}</div>
                <div className="name">
                  <span>
                    <small>All Users</small>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => navigate("/payment-request")}
            >
              <div className="card-icon">
                <AccountBalanceIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{payment?.length}</div>
                <div className="name">
                  <span>
                    <small>Payment Req</small>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => navigate("/withdraw-request")}
            >
              <div className="card-icon">
                <AccountBalanceWalletIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{withdrawals?.length}</div>
                <div className="name">
                  <span>
                    <small>Withdrawal Req</small>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="dashboard-card"
              onClick={() => navigate("/admin-quiz")}
            >
              <div className="card-icon">
                <QuizIcon className="refer-icon" />
              </div>
              <div className="content">
                <div className="count">{allQuiz?.length}</div>
                <div className="name">
                  <span>
                    <small>All Quiz</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </Layout>
  );
};

export default Dashboard;
