import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { message } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";

const PaymentRequest = () => {
  const [payment, setPayment] = useState(null);
  const [select, setSelect] = useState("All");

  // approve payment
  const handleApprove = (id, email, amount) => {
    axios
      .post("/api/payment/approve-payment", {
        paymentId: id,
        email: email,
        amount: amount,
      })
      .then((res) => {
        message.success(res.data.message);
        getAllPayment();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // reject payment
  const handleReject = () => {};

  // get all payment
  const getAllPayment = async () => {
    axios
      .get("/api/payment/get-all-payment")
      .then((res) => {
        setPayment(res.data.data.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllPayment();
  }, []);

  const data = payment?.filter((item) => item.status === select);
  const paymentData = select === "All" ? payment : data;

  return (
    <Layout>
      <DashboardLayout>
        <div className="header">
          <div className="d-flex justify-content-between ">
            <h5 className="p-0">Payment List</h5>
            <select
              name="select"
              id=""
              onChange={(e) => setSelect(e.target.value)}
            >
              <option value="All">All</option>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="reject">Reject</option>
            </select>
          </div>
        </div>
        <div className="all-quiz row gap-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Email</th>
                <th scope="col">UTR</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {payment &&
              paymentData.map((item, index) => {
                return (
                  <tbody>
                    <tr>
                      <td data-label="#">{index + 1}</td>
                      <td data-label="Email">{item?.email}</td>
                      <td data-label="UTR">{item?.utr}</td>
                      <td data-label="Amount">{item?.amount}</td>
                      <td data-label="Amount">{item?.status}</td>
                      <td data-label="Amount">
                        {new Date(item?.date).toLocaleString("en", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </td>
                      <td data-label="Action">
                        {item?.status === "pending" ? (
                          <>
                            <button
                              onClick={() =>
                                handleApprove(
                                  item?._id,
                                  item?.email,
                                  item?.amount
                                )
                              }
                              className="delete-quiz-btn me-2 my-2"
                            >
                              Approve
                            </button>
                            <button
                              onClick={handleReject}
                              className="delete-quiz-btn bg-danger text-white"
                            >
                              Reject
                            </button>
                          </>
                        ) : (
                          "--"
                        )}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default PaymentRequest;
