import React from "react";
import { Link } from "react-router-dom";
import IMAGES from "../../img/image";
import "../Footer/Footer.css";

const Footer = () => {
  return (
    <div className="container-fluid text-white footer-container">
      <div className="row">
        <div className="mb-4 col-12 col-sm-12 col-md-4 col-lg-4">
          <div className="footer-logo m-0 p-0">
            <span className="cursor-pointer">
              <img src={IMAGES.logo} alt="" />
            </span>
          </div>
          <div className="footer-para">
            <span>
              Genius Gate is bringing the learner community of all age groups
              under one roof to learn, implement, and grow with the dynamic
              skills of the future to help you stand out of the crowd.
            </span>
          </div>
        </div>
        <div className="mb-4 col-6 col-sm-6 col-md-3 col-lg-3">
          <h5 className="line">Support</h5>
          <div className="support-links d-flex flex-column">
            <Link to="/contact">Contact</Link>
            {/* <Link to="/disclaimer">Disclaimer</Link> */}
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
            <Link to="/refund-policy">Refund Policy</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
        </div>
        <div className="mb-4 col-6 col-sm-6 col-md-3 col-lg-3">
          <h5 className="line">Useful Links</h5>
          <div className="support-links d-flex flex-column">
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
            {/* <Link to="/service">Services</Link> */}
            <Link to="/register">Became an Affiliate</Link>
          </div>
        </div>
        <div className="mb-4 col-12 col-sm-12 col-md-2 col-lg-2">
          <h5 className="line">Partnership</h5>
          <div className="support-links d-flex flex-column">
            <Link>Become an investor (coming soon)</Link>
          </div>
        </div>
      </div>
      <hr style={{ color: "#fff" }} />
      <div className="copyright text-center">
        All Rights Reserved © 2023 | Genius Gate PVT. LTD. | Designed &
        Developed by{" "}
        <Link
          className="text-warning"
          target="_blank"
          to="https://aashirdigital.com"
        >
          ~aashirdigital
        </Link>
      </div>
    </div>
  );
};

export default Footer;
