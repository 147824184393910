import React, { useEffect } from "react";
import Header from "../components/Header/Header";
import "./About.css";
import WeAre from "../components/About/WeAre";
import Text from "../components/About/Text";
import Layout from "../components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import axios from "axios";

const About = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Layout>
      <div className="container-fluid about-container pt-5">
        {/* <WeAre /> */}
        <Text />
      </div>
    </Layout>
  );
};

export default About;
