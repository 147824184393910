import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import axios from "axios";
import "./ActivationFees.css";
import { message } from "antd";
import { setRef } from "@mui/material";

const ActivationFees = () => {
  const [data, setData] = useState(null);
  const [fees, setFees] = useState(null);
  const [referAmount, setReferAmount] = useState(null);

  const addFees = async (e) => {
    e.preventDefault();
    if (data?.length <= 0) {
      try {
        const res = await axios.post("/api/fees/add-fees", {
          fees,
          referAmount,
        });
        if (res.data.success) {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await axios.post("/api/fees/update-fees", {
          fees,
          referAmount,
        });
        if (res.data.success) {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getFees = async () => {
    try {
      const res = await axios.get("/api/fees/get-fees");
      if (res.data.success) {
        setFees(res.data.data[0]?.activationFees);
        setReferAmount(res.data.data[0]?.referAmount);
        setData(res.data.data);
      } else {
        console.log("failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFees();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Set an activation fees!</b>
          </span>
        </div>
        <form className="form" onSubmit={addFees}>
          <div className="mb-3">
            <label className="form-label" htmlFor="fees">
              Enter Fees
            </label>
            <input
              className="form-control"
              type="text"
              name="activationFees"
              placeholder="Enter account activation fee"
              value={fees}
              onChange={(e) => setFees(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="ReferAmount">
              Enter Refer Amount
            </label>
            <input
              className="form-control"
              type="text"
              name="referAmount"
              placeholder="Enter refer amount"
              value={referAmount}
              onChange={(e) => setReferAmount(e.target.value)}
            />
          </div>
          <button className="activate-btn">Submit</button>
        </form>
      </DashboardLayout>
    </Layout>
  );
};

export default ActivationFees;
