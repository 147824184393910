import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./Register.css";

const Login = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({});

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    try {
      const res = await axios.post("/api/user/login", form);
      if (res.data.success) {
        localStorage.setItem("token", res.data.token);
        message.success(res.data.message);
        navigate("/user-dashboard");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div className="container register-container">
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="form col-12 col-sm-12 col-md-6 col-lg-6 d-block m-auto">
              <h6>Welcome Back! Login Now..</h6>
              <div className="hr-line"></div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Email
                </label>
                <input
                  name="email"
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                />
              </div>
              <div className="mb-3 form-fields">
                <label className="form-label" htmlFor="name">
                  Password
                </label>
                <input
                  name="password"
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                />
              </div>
              <p className="m-0 p-0 mb-3">
                Forgot Password? <Link to="/forgot-password">click here</Link>
              </p>
              <div className="mb-3 register-btn">
                <button>Login</button>
              </div>
              <div className="hr-line"></div>
              <p>
                Not a User? <Link to="/register">click here</Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
