import React, { useState } from "react";
import "./AddMoney.css";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import IMAGES from "../img/image";
import { message } from "antd";
import { useSelector } from "react-redux";
import axios from "axios";

const AddMoney = () => {
  const { user } = useSelector((state) => state.user);
  const [utr, setUtr] = useState(null);
  const [amount, setAmount] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/payment/add-money", {
        utr: utr,
        amount: amount,
        email: user?.email,
      });
      if (res.data.success) {
        message.success(res.data.message);
        setAmount("");
        setUtr("");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Add Money to your wallet</b>
          </span>
        </div>
        <div className="add-money-container">
          <div className="row">
            <div className="mb-5 col-12 col-sm-12 col-md-6 col-lg-6 barcode-image">
              <img src={IMAGES.barcode} alt="" />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 barcode-form">
              <form onSubmit={handleSubmit}>
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="Utr">
                    Enter Amount
                  </label>
                  <input
                    name="utr"
                    onChange={(e) => setAmount(e.target.value)}
                    className="form-control"
                    placeholder="For Ex: 100"
                    value={amount}
                    type="number"
                  />
                </div>
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="Utr">
                    Enter UTR
                  </label>
                  <input
                    name="utr"
                    onChange={(e) => setUtr(e.target.value)}
                    className="form-control"
                    placeholder="For Ex: 3278965412354"
                    value={utr}
                    type="text"
                  />
                </div>
                <button type="submit" className="hero-btn m-0">
                  Verify & Submit
                </button>
              </form>
              <div className="points my-4">
                <p>Note</p>
                <p className="m-0 p-0">
                  <small>1) Transaction will be verified by Admin</small>
                </p>
                <p className="m-0 p-0">
                  <small>
                    2) Enter Correct UTR for successful verification
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default AddMoney;
