import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CallIcon from "@mui/icons-material/Call";
import QuizIcon from "@mui/icons-material/Quiz";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./SideMenu.css";
import { message } from "antd";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    message.success("Logout Successful");
    navigate("/login");
  };
  return (
    <div
      className={`sidemenu-container d-block d-md-block d-lg-none ${
        sideMenu ? "active" : ""
      }`}
    >
      <div className="sidemenu">
        <ul className="p-0">
          {!user && (
            <li>
              <HomeIcon className="me-2" />
              <Link to="/">Home</Link>
            </li>
          )}
          {user && (
            <li>
              <HomeIcon className="me-2" />
              <Link to="/user-dashboard">Dashboard</Link>
            </li>
          )}
          <li>
            <InfoIcon className="me-2" />
            <Link to="/about">About Us</Link>
          </li>
          <li>
            <QuizIcon className="me-2" />
            <Link to="/course">Courses</Link>
          </li>
          <li>
            <CallIcon className="me-2" />
            <Link to="/contact">Contact Us</Link>
          </li>
          {/* <li>
            <EngineeringIcon className="me-2" />
            <Link to="/contact">Services</Link>
          </li> */}
          {!user && (
            <div className="sidemenu-action-btn">
              <button
                onClick={() => navigate("/login")}
                className="request-btn mb-3"
              >
                Login
              </button>
              <button
                onClick={() => navigate("/register")}
                className="request-btn"
              >
                Register
              </button>
            </div>
          )}
          {user && (
            <div className="sidemenu-action-btn">
              <button onClick={handleLogout} className="request-btn">
                Logout
              </button>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
