import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { message } from "antd";
import axios from "axios";

const Users = () => {
  const [users, setUsers] = useState(null);
  const getAllUsers = async () => {
    try {
      const res = await axios.get("/api/user/get-all-users");
      if (res.data.success) {
        setUsers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteUser = async (id) => {
    try {
      const res = await axios.post("/api/user/delete-user", { userId: id });
      if (res.data.success) {
        message.success(res.data.message);
        getAllUsers();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <Layout>
      <DashboardLayout>
        <div className="header">
          <h5 className="p-0">All Users List</h5>
        </div>
        <div className="all-quiz row gap-2">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            {users &&
              users.map((user, index) => {
                {
                  /* const totalQuestions = quiz.questions.length; */
                }
                return (
                  <tbody>
                    <tr>
                      <td data-label="#">{index + 1}</td>
                      <td data-label="Quiz Name">{user.name}</td>
                      <td data-label="Quiz Time">{user.email}</td>
                      <td data-label="Total QUestion">{user.phone}</td>
                      <td data-label="Action">
                        <button
                          onClick={() => deleteUser(user._id)}
                          className="delete-quiz-btn"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default Users;
