import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./Test.css";
import TestSubmit from "./TestSubmit";
import { useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";

const Test = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [clickedOption, setClickedOption] = useState(0);
  const [score, setScore] = useState(0);
  const [testSubmit, setTestSubmit] = useState(false);
  const [test, setTest] = useState([]);
  const [quiz, setQuiz] = useState([]);

  // const [remainingTime, setRemainingTime] = useState(0);
  const params = useParams();
  const getQuizByName = async () => {
    try {
      const res = await axios.post("/api/quiz/get-quiz-name", {
        name: params.name,
      });
      if (res.data.success) {
        setTest(res.data.data.questions);
        setQuiz(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQuizByName();
  }, []);

  const changeQuestion = () => {
    updateScore();
    setClickedOption(0);
    if (currentQuestion < test?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setTestSubmit(true);
    }
  };

  const updateScore = () => {
    if (clickedOption === parseInt(test[currentQuestion]?.answer)) {
      setScore(score + 1);
    }
  };

  // ================= Quiz Timer
  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    const quizEndTime = new Date();
    const quizDuration = quiz?.quizTime * 60 * 1000; // Convert minutes to milliseconds
    quizEndTime.setTime(quizEndTime.getTime() + quizDuration);

    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = quizEndTime - now;
      if (timeDifference <= 0) {
        clearInterval(interval);
        setRemainingTime(0);
        setTestSubmit(true);
      } else {
        setRemainingTime(timeDifference);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [quiz]);

  useEffect(() => {
    if (testSubmit === true) {
      // updating score
      axios
        .post("/api/user/update-score", {
          email: user?.email,
          quizId: quiz._id,
          score: score,
        })
        .then((res) => {
          if (res.data.success) {
            dispatch(setUser(res.data.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // updating score ends
    }
  }, [testSubmit]);

  const minutes = Math.floor(remainingTime / 60000); // 1 minute = 60000 milliseconds
  const seconds = Math.floor((remainingTime % 60000) / 1000); // remaining milliseconds after minutes removed
  // ================= Quiz Timer ENDS

  return (
    <>
      <div className="test-container">
        {testSubmit && testSubmit ? (
          <TestSubmit score={score} />
        ) : (
          <>
            <div className="test-heading-container">
              <div className="test-heading">
                <span className="quiz-name">
                  <b>{quiz.quizName}</b>
                </span>
                <span className="question-number">
                  <b>
                    Questions {currentQuestion + 1}/{test.length}
                  </b>
                </span>
                <span>
                  <b>
                    Remaining Time: {minutes} minutes {seconds} seconds
                  </b>
                </span>
              </div>
            </div>
            <div className="test-body-container">
              <div className="test-body">
                <h6>
                  <b>
                    {currentQuestion + 1}. {test[currentQuestion]?.question}
                  </b>
                </h6>
                {test[currentQuestion]?.options.map((item, i) => {
                  return (
                    <span
                      key={i}
                      onClick={() => setClickedOption(i + 1)}
                      className={`options ${
                        clickedOption - 1 === i && "active"
                      }`}
                    >
                      {item}
                    </span>
                  );
                })}
              </div>
            </div>
            <div className="action-btn-container">
              <div className="action-btns">
                {/* <button
                  className={`${currentQuestion === 0 && "d-none"}`}
                  onClick={() => setCurrentQuestion(currentQuestion - 1)}
                >
                  Prev
                </button> */}
                <button
                  className={`${
                    currentQuestion === test?.length - 1 && "d-none"
                  }`}
                  onClick={changeQuestion}
                >
                  Next
                </button>
                <button
                  className={`${
                    currentQuestion === test?.length - 1 ? "d-block" : "d-none"
                  }`}
                  onClick={changeQuestion}
                >
                  Submit
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Test;
