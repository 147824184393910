import React from "react";
import "./HowItWorks.css";
import IMAGES from "../../img/image";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import StarsIcon from "@mui/icons-material/Stars";
import PaidIcon from "@mui/icons-material/Paid";

const HowItWorks = () => {
  return (
    <div className="py-5 container-fluid how-it-works">
      <h2 className="text-lg-center mb-4">
        How
        <span> Genius Gate</span> Works?
      </h2>
      <div className="row">
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <HowToRegIcon style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Sign Up</h5>
          <h6 className="mb-4">Be a part of Genius Gate</h6>
          <p className="text-muted">
            Unleash Your Potential: Ignite Your Career with Cutting-Edge
            Education, Team Projects, and a Thriving Network. Be a Part of
            Crafting the Future of Work, Together!
          </p>
        </div>
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <StarsIcon style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Activate Account</h5>
          <h6 className="mb-4">Get the premium access</h6>
          <p className="text-muted">
            Fuel Your Career with Premium Access: Unlimited Content, Advanced
            Workshops, Personalized Coaching, Priority Support. Elevate Your
            Journey with Edyguru's Exclusive Membership for Accelerated Growth!
          </p>
        </div>
        <div className="px-3 px-lg-5 text-center col-12 col-sm-12 col-md-4 col-lg-4 mb-4">
          <PaidIcon style={{ fontSize: "70px" }} />
          <h5 className="fw-bold my-2">Earn Money</h5>
          <h6 className="mb-4">Get Instant withdrawal</h6>
          <p className="text-muted">
            Experience Swift Financial Freedom: Enjoy the Power of Instant
            Earnings Withdrawal. Say goodbye to waiting periods and access your
            hard-earned money instantly, putting you in control of your
            financial journey.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
