import React, { useEffect, useState } from "react";
import "./QuizLeaderboard.css";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";

const QuizLeaderboard = () => {
  const [users, setUsers] = useState();
  const params = useParams();

  const getQuizRanking = async () => {
    try {
      const res = await axios.post("/api/quiz/get-users-ranking", {
        quizId: params.id,
      });
      if (res.data.success) {
        const usersWithScores = res.data.data.map((user) => {
          const highestScoreObject = user.quiz.find(
            (q) => q.quizId === params.id
          );
          const highestScore = highestScoreObject
            ? highestScoreObject.score
            : 0;
          return {
            ...user,
            highestScore,
          };
        });
        // Sort the users based on highestScore in descending order
        const sortedUsers = usersWithScores.sort(
          (a, b) => b.highestScore - a.highestScore
        );
        setUsers(sortedUsers);
        // setUsers(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuizRanking();
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="admin-quiz-container">
          <div className="header">
            <h5 className="p-0">{params.quizName} Ranking</h5>
          </div>
          <div className="all-quiz row gap-2">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Payment Details</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.map((item, index) => {
                    console.log(item);
                    const elm = item.quiz.find((q) => q.quizId === params.id);
                    return (
                      <>
                        <tr key={index}>
                          <td data-label="#">{index + 1}</td>
                          <td data-label="Name">{item.name}</td>
                          <td data-label="Email">{item.email}</td>
                          <td data-label="Payment Details">
                            <button
                              type="button"
                              className="delete-quiz-btn my-2 bg-warning"
                              data-bs-toggle="modal"
                              data-bs-target={`#exampleModal-${item._id}`}
                            >
                              View
                            </button>
                          </td>
                          <td data-label="Email">{elm?.score}</td>
                        </tr>
                        <div
                          className="modal fade"
                          id={`exampleModal-${item._id}`}
                          tabIndex="-1"
                          aria-labelledby={`exampleModalLabel-${item._id}`}
                          aria-hidden="true"
                        >
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  className="modal-title"
                                  id={`exampleModalLabel-${item._id}`}
                                >
                                  Payment Details
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div class="modal-body">
                                <p>
                                  Holder Name -{" "}
                                  {item?.paymentMethod?.holderName}
                                </p>
                                <p>
                                  Bank Account Number -{" "}
                                  {item?.paymentMethod?.accountNumber}
                                </p>
                                <p>IFSC - {item?.paymentMethod?.ifsc}</p>
                                <p>
                                  Paytm/Gpay/Phonepe -{" "}
                                  {item?.paymentMethod?.paytm}
                                </p>
                                <p>UPI - {item?.paymentMethod?.upi}</p>
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default QuizLeaderboard;
