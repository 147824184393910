import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import "./WithdrawMoney.css";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import axios from "axios";
import { setUser } from "../redux/features/userSlice";

const WithdrawMoney = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [method, setMethod] = useState(null);
  const dispatch = useDispatch();

  // withdrawal
  const handleWithdraw = async (e) => {
    e.preventDefault();
    if (method === "Select" || method === null) {
      message.error("Please Select Payment Method");
    } else if (withdrawAmount === null || withdrawAmount < 50) {
      message.error("Minimum Withdrawal is 50");
    } else {
      try {
        const res = await axios.post("/api/withdraw/add-withdraw", {
          amount: parseInt(withdrawAmount),
          email: user?.email,
          paymentDetails: paymentMethod,
          method: method,
        });
        if (res.data.success) {
          message.success(res.data.message);
          setMethod("Select");
          setWithdrawAmount(0);
          dispatch(setUser(res.data.data));
        } else {
          message.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // payment details
  const handleChange = (e) => {
    setPaymentMethod({ ...paymentMethod, [e.target.name]: e.target.value });
  };

  // submit for add payment details
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/user/add-payment-method", {
        email: user?.email,
        paymentMethod,
      });
      if (res.data.success) {
        message.success(res.data.message);
        setPaymentMethod(res.data.data.paymentMethod);
        dispatch(setUser(res.data.data));
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user !== null) {
      setPaymentMethod(user?.paymentMethod);
    }
  }, []);

  return (
    <Layout>
      <DashboardLayout>
        <div className="name-container">
          <span>
            <b>Withdraw Money to your Account!</b>
          </span>
        </div>
        <div className="payment-method-container">
          {/* buttons  */}
          <div className="tab-btn">
            <button
              className={`${tab === 0 ? "activeBtn pay-btn" : "pay-btn"}`}
              onClick={() => setTab(0)}
            >
              Payment Method
            </button>
            <button
              className={`${tab === 1 ? "activeBtn pay-btn" : "pay-btn"}`}
              onClick={() => setTab(1)}
            >
              Withdraw Money
            </button>
          </div>
          {/* Payment Method  */}
          <div className="row">
            {tab === 0 && (
              <div className="payment-method">
                <b>Add Payment Details</b>
                <hr className="mb-4" />
                <form onSubmit={handleSubmit}>
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Account Holder Name
                    </label>
                    <input
                      placeholder="Enter Account Holder Name"
                      className="form-control"
                      type="text"
                      onChange={handleChange}
                      name="holderName"
                      value={paymentMethod?.holderName}
                    />
                  </div>
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Bank Account Number
                    </label>
                    <input
                      placeholder="Enter Bank Account Number"
                      className="form-control"
                      type="text"
                      onChange={handleChange}
                      name="accountNumber"
                      value={paymentMethod?.accountNumber}
                    />
                  </div>
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      IFSC Code
                    </label>
                    <input
                      placeholder="Enter IFSC Code"
                      className="form-control"
                      type="text"
                      onChange={handleChange}
                      name="ifsc"
                      value={paymentMethod?.ifsc}
                    />
                  </div>
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      Paytm/Gpay/Phonepe Number
                    </label>
                    <input
                      placeholder="Enter Paytm Number"
                      className="form-control"
                      type="text"
                      onChange={handleChange}
                      name="paytm"
                      value={paymentMethod?.paytm}
                    />
                  </div>
                  <div className="mb-3 form-fields">
                    <label className="form-label" htmlFor="">
                      UPI Handle
                    </label>
                    <input
                      placeholder="Enter UPI Handle"
                      className="form-control"
                      type="text"
                      onChange={handleChange}
                      name="upi"
                      value={paymentMethod?.upi}
                    />
                  </div>
                  <button type="submit" className="hero-btn m-0">
                    Submit
                  </button>
                </form>
              </div>
            )}
            {/* Withdraw Money */}
            {tab === 1 && (
              <div className="withdraw-money">
                <b>Your balance: {user?.amount}</b>
                <hr />
                <div className="mb-3 form-fields">
                  <label className="form-label" htmlFor="">
                    Enter Amount
                  </label>
                  <input
                    placeholder="Enter amount to withdraw"
                    className="form-control"
                    type="number"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                  />
                </div>
                <div className="mb-3 form-fields">
                  <select
                    onChange={(e) => setMethod(e.target.value)}
                    value={method}
                    className="form-select"
                    name="method"
                    required
                  >
                    <option value="Select">Select Payment Method</option>
                    <option value="bank">Bank</option>
                    <option value="paytm">Paytm/Gpay/Phonepe</option>
                    <option value="upi">Upi</option>
                  </select>
                </div>
                <button
                  onClick={handleWithdraw}
                  className="hero-btn m-0 disabled"
                >
                  Withdraw
                </button>
              </div>
            )}
          </div>
        </div>
      </DashboardLayout>
    </Layout>
  );
};

export default WithdrawMoney;
