import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPass from "./pages/ForgotPass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import Dashboard from "./user/Dashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import Quiz from "./user/Quiz";
import QuizDetails from "./components/Quiz/QuizDetails";
import AddQuiz from "./admin/AddQuiz";
import Test from "./user/Test";
import AdminQuiz from "./admin/AdminQuiz";
import Users from "./admin/Users";
import Profile from "./user/Profile";
import Refer from "./user/Refer";
import ActivationFees from "./admin/ActivationFees";
import MyQuiz from "./user/MyQuiz";
import QuizResult from "./user/QuizResult";
import AddMoney from "./user/AddMoney";
import PaymentRequest from "./admin/PaymentRequest";
import WithdrawMoney from "./user/WithdrawMoney";
import WithdrawalRequest from "./admin/WithdrawalRequest";
import QuizLeaderboard from "./admin/QuizLeaderboard";
import ResetPassword from "./pages/ResetPassword";
import Course from "./pages/Course";
import CourseFaq from "./components/Course/CourseFaq";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* pages */}
        <Route path="/" element={<Home />} />
        <Route
          path="/register/:referCode?"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/forgot-password" element={<ForgotPass />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service" element={<Service />} />
        <Route path="/course" element={<Course />} />
        <Route path="/course-detail/:id?" element={<CourseFaq />} />
        <Route
          path="/quiz-details/:name?"
          element={
            <ProtectedRoute>
              <QuizDetails />
            </ProtectedRoute>
          }
        />
        {/* User pages  */}
        <Route
          path="/user-dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/refer-link"
          element={
            <ProtectedRoute>
              <Refer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-money"
          element={
            <ProtectedRoute>
              <AddMoney />
            </ProtectedRoute>
          }
        />
        <Route
          path="/withdraw-money"
          element={
            <ProtectedRoute>
              <WithdrawMoney />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quiz"
          element={
            <ProtectedRoute>
              <Quiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quiz-result"
          element={
            <ProtectedRoute>
              <QuizResult />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myquiz"
          element={
            <ProtectedRoute>
              <MyQuiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/test/:name?"
          element={
            <ProtectedRoute>
              <Test />
            </ProtectedRoute>
          }
        />
        {/* Admin Pages */}
        <Route
          path="/add-quiz"
          element={
            <ProtectedRoute>
              <AddQuiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-quiz"
          element={
            <ProtectedRoute>
              <AdminQuiz />
            </ProtectedRoute>
          }
        />
        <Route
          path="/all-users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-activation-fees"
          element={
            <ProtectedRoute>
              <ActivationFees />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment-request"
          element={
            <ProtectedRoute>
              <PaymentRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/withdraw-request"
          element={
            <ProtectedRoute>
              <WithdrawalRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quiz-leaderboard/:quizName/:id?"
          element={
            <ProtectedRoute>
              <QuizLeaderboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
