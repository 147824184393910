import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import IMAGES from "../../img/image";
import { useDispatch, useSelector } from "react-redux";
import "./QuizDetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import { setUser } from "../../redux/features/userSlice";

const QuizDetails = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [quiz, setQuiz] = useState(null);
  const [fees, setFees] = useState(null);

  // handle refer amount
  const addReferAmount = async () => {
    try {
      const res = await axios.post("/api/user/add-refer-amount", {
        referCode: user?.referCode,
        amount: fees?.referAmount,
      });
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // buy quiz
  const handleBuyQuiz = async () => {
    axios
      .post("/api/user/buy-quiz", {
        email: user?.email,
        quizId: quiz?._id,
        quizName: quiz?.quizName,
        quizDate: quiz?.quizDate,
        quizPrice: quiz?.quizPrice,
        result: quiz?.result,
        amount: user?.amount,
      })
      .then((res) => {
        dispatch(setUser(res.data.data));
        message.success(res.data.message);
        addReferAmount();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // appear quiz
  const handleAppearQuiz = () => {
    axios
      .post("/api/user/appear-quiz", {
        email: user?.email,
        quizId: quiz._id,
      })
      .then((res) => {
        if (res.data.success) {
          message.success("Exam has started.");
          dispatch(setUser(res.data.data));
          navigate(`/test/${quiz.quizName}`);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get quiz by name
  const getQuizByName = async () => {
    try {
      const res = await axios.post("/api/quiz/get-quiz-name", {
        name: params.name,
      });
      if (res.data.success) {
        setQuiz(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get fees
  const getFees = async () => {
    try {
      const res = await axios.get("/api/fees/get-fees");
      if (res.data.success) {
        setFees(res.data.data[0]);
      } else {
        console.log("failed");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFees();
    getQuizByName();
  }, []);

  // checking if the user has bought the quiz or not
  const quizData = user?.quiz.find((item) => item.quizName === params.name);

  // Checking if the current date is greater than or equal to the quiz date
  const canAppearNow =
    quiz && new Date(quiz?.quizDate).getDate() === new Date().getDate();

  return (
    <Layout>
      {user && (
        <DashboardLayout>
          <div className="quiz-detail-container">
            <div className="quiz-left">
              <img src={IMAGES.course} alt="" />
            </div>
            <div className="quiz-right">
              <h4>{quiz && quiz.quizName} Quiz</h4>
              <span className="my-3">
                Total Question: {quiz && quiz.questions.length}
              </span>
              <br />
              <span className="my-3">
                Quiz Duration: {quiz && quiz.quizTime} Mins
              </span>
              <br />
              <span className="my-3">
                Quiz Price: {quiz && quiz.quizPrice}rs
              </span>
              <br />
              {quizData ? (
                quizData.appeared ? (
                  <button className="appear-quiz-btn bg-danger text-white">
                    Already Appeared
                  </button>
                ) : canAppearNow ? (
                  <button
                    className="appear-quiz-btn"
                    onClick={handleAppearQuiz}
                  >
                    Appear Now
                  </button>
                ) : new Date(quiz?.quizDate).getDate() <
                  new Date().getDate() ? (
                  <button className="appear-quiz-btn">Expired</button>
                ) : (
                  <button className="appear-quiz-btn">
                    You can appear on{" "}
                    {new Date(quiz?.quizDate).toLocaleString("default", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </button>
                )
              ) : parseInt(user?.amount) >= parseInt(quiz?.quizPrice) ? (
                <button className="appear-quiz-btn" onClick={handleBuyQuiz}>
                  Buy Now
                </button>
              ) : (
                <button
                  className="appear-quiz-btn"
                  onClick={() => navigate("/add-money")}
                >
                  Add Money to Buy
                </button>
              )}
              {quizData ? (
                quizData.appeared ? (
                  <p>
                    Hey! You have already appeared for this quiz. Find some new{" "}
                    <Link to="/quiz">quiz here</Link>
                  </p>
                ) : (
                  <p>
                    {" "}
                    Note: When you click on appear now the quiz will start
                    immediately and do not press back button otherwise the data
                    will be lost. Appearing on quiz is one time only.
                  </p>
                )
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </DashboardLayout>
      )}
      {/* {!user && (
        <div className="quiz-detail-container">
          <div className="quiz-left">
            <h4>Course Name</h4>
            <img width="30%" src={IMAGES.js} alt="" />
          </div>
          <div className="quiz-right">
            <h4>What you will learn?</h4>
            <p>- hello</p>
            <p>- hello</p>
            <p>- hello</p>
            <p>- hello</p>
            <p>- hello</p>
            <button onClick={() => navigate("/login")}>Buy Now</button>
          </div>
        </div>
      )} */}
    </Layout>
  );
};

export default QuizDetails;
