import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import "./Course.css";
import IMAGES from "../img/image";

const Course = () => {
  const navigate = useNavigate();
  const courses = [
    {
      img: `${IMAGES.nmms}`,
      heading: "NMMS PREPARATION",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, tenetur!",
    },
    {
      img: `${IMAGES.ntse}`,
      heading: "NTSE PREPARATION",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, tenetur!",
    },
    {
      img: `${IMAGES.typing}`,
      heading: "ENGLISH TYPING AND SPEAKING",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, tenetur!",
    },
    {
      img: `${IMAGES.com}`,
      heading: "COMMUNICATION SKILLS",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, tenetur!",
    },
    {
      img: `${IMAGES.web}`,
      heading: "WEB DESIGNING",
      desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, tenetur!",
    },
  ];
  return (
    <Layout>
      {" "}
      <div className="hero-section">
        <div className="content">
          <span style={{ color: "#1dd1a1" }}>
            <small>Genius Gate</small>
          </span>
          <h1>Our Courses</h1>
          <p>
            Genius Gate is an online learning and teaching marketplace with over
            100+ courses that can improve your skill better
          </p>
          <div className="d-flex action-btn">
            <button
              onClick={() => navigate("/contact")}
              className="hero-btn me-2"
            >
              Contact Us
            </button>
            <button
              onClick={() => navigate("/register")}
              className="enroll-btn"
            >
              Enroll Now
            </button>
          </div>
        </div>
        <div className="hero-bg course-bg">
          <img src={IMAGES.courseBg} alt="" />
        </div>
      </div>
      <div className="courses-container container">
        <h2 className="text-center mb-5">
          <b>Courses We Offer</b>
        </h2>
        <div className="row gap-5 text-center d-flex justify-content-center">
          {courses?.map((item, index) => {
            return (
              <div
                key={index}
                className="col-12 col-sm-12 shd col-md-3 col-lg-3 p-0"
              >
                <div className="">
                  <img className="course-img" src={item.img} alt="" />
                </div>
                <div className="course-content mt-3">
                  <h6>{item.heading}</h6>
                  {/* <p>{item.desc}</p> */}
                </div>
                <div
                  onClick={() => navigate(`/course-detail/${index + 1}`)}
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#1dd1a1",
                    padding: "10px",
                  }}
                  className="text-white"
                >
                  View Course
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default Course;
